<template>
  <div class="detail-index-bg">
    <div class="detail-index" v-loading="pageLoading">
      <Crumbs/>
      <!-- <div class="detail-index-crumbs">
        <span
          class="detail-index-crumbs-up"
          @click="goBack()"
          style="color:black;cursor:pointer"
          >{{currentName}}</span
        >
        /
        <span class="detail-index-crumbs-this">{{ info.title }}</span>
      </div> -->
      <div class="detail-index-top">
        <div class="detail-index-top-content-headPic">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/border-top.png"
            alt=""
          />
        </div>
        <div class="detail-index-top-content-footerPic">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
            alt=""
          />
        </div>
        <div class="detail-index-top-left">
          <img
            style="width: 100%; height: 100%"
            :src="info.cover"
            :alt="info.title"
          />
        </div>
        <div class="detail-index-top-right">
          <div class="right-top">
          <div class="culture-detail-top-title">
            {{ info.title }}
          </div>
          <div class="culture-detail-top-des">
            <ul class="culture-detail-top-des-ul">
              <li class="culture-detail-top-des-ul-li">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_speaker_culture.png"
                  alt=""
                />
                <div class="culture-detail-top-des-ul-li-div">
                  <span>主讲人：</span>{{ info.speaker }}
                </div>
              </li>
              <li class="culture-detail-top-des-ul-li">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_date_culture.png"
                  alt=""
                />
                <div class="culture-detail-top-des-ul-li-div">
                  <span>开课时间：</span>{{ info.start_date }}
                </div>
              </li>
              <li class="culture-detail-top-des-ul-li">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_date_culture.png"
                  alt=""
                />
                <div class="culture-detail-top-des-ul-li-div">
                  <span>结课时间：</span>{{ info.end_date }}
                </div>
              </li>
              <li class="culture-detail-top-des-ul-li">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_chapter_culture.png"
                  alt=""
                />
                <div class="culture-detail-top-des-ul-li-div">
                  <span>课程章节：</span>{{ info.chapter_count }}节
                </div>
              </li>
              <li class="culture-detail-top-des-ul-li">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_successor_intangible.png"
                  alt=""
                />
                <div class="culture-detail-top-des-ul-li-div">
                  <span>授课老师：</span>{{ info.lecturer }}
                </div>
              </li>
            </ul>
          </div>
          </div>
          <div class="culture-detail-top-button" @click="showCode()" v-if="info.course_status == 0">
            立即报名
          </div>
          <div class="culture-detail-top-button gray" v-if="info.course_status == 1">
            报名已结束
          </div>
          <div class="culture-detail-top-button gray" v-if="info.course_status == 2">
            课程已结束
          </div>
          <!-- <a href="javascript:void(0)" @click="showCode()" v-if="info.course_status == 0">
            <div class="culture-detail-top-button">立即报名</div>
          </a>
          <a href="javascript:void(0)" v-if="info.course_status == 1">
            <div class="culture-detail-top-button started">已开课</div>
          </a>
          <a href="javascript:void(0)" v-if="info.course_status == 2">
            <div class="culture-detail-top-button finished">已结束</div>
          </a> -->
        </div>
      </div>
      <div class="detail-index-bottom">
        <div class="detail-index-bottom-content">
          <div class="detail-index-top-content-footerPic">
            <img
              style="width: 100%; height: 100%"
              src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
              alt=""
            />
          </div>
          <div class="detail-index-bottom-content-top">
            <div class="detail-index-bottom-content-top-title">课程详情</div>
          </div>

          <div class="detail-index-bottom-content-bottom">
            <div class="detail-index-bottom-content-bottom-box" v-if="info.content">
              <div class="detail-index-bottom-content-bottom-box-top">
                <div class="detail-index-bottom-content-bottom-box-top-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_title_left_icon.png"
                    alt=""
                  />
                </div>
                <div class="detail-index-bottom-content-bottom-box-top-title">
                  课程内容
                </div>
              </div>
              <div class="detail-index-bottom-content-bottom-box-bottom">
                <div v-html="info.content">{{ info.content }}</div>
              </div>
            </div>
            <div class="detail-index-bottom-content-bottom-box" v-if="info.remark">
              <div class="detail-index-bottom-content-bottom-box-top">
                <div class="detail-index-bottom-content-bottom-box-top-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_title_left_icon.png"
                    alt=""
                  />
                </div>
                <div class="detail-index-bottom-content-bottom-box-top-title">
                  报名须知
                </div>
              </div>
              <div class="detail-index-bottom-content-bottom-box-bottom">
                <div>{{ info.remark }}</div>
              </div>
            </div>
            <div class="detail-index-bottom-content-bottom-box">
              <div class="detail-index-bottom-content-bottom-box-top">
                <div class="detail-index-bottom-content-bottom-box-top-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_title_left_icon.png"
                    alt=""
                  />
                </div>
                <div class="detail-index-bottom-content-bottom-box-top-title">
                  授课大纲
                </div>
              </div>
              <div class="detail-index-bottom-content-bottom-box-bottom">
                <div v-if="info.chapter_list">
                  <ul class="culture-detail-bottom-summary-ul">
                    <li
                      class="culture-detail-bottom-summary-ul-li"
                      v-for="(chapter, index) in info.chapter_list"
                      :key="index"
                    >
                      <div class="culture-detail-bottom-summary-ul-li-title">
                        {{ index + 1 }}. {{ chapter.name }}
                      </div>
                      <ol
                        class="culture-detail-bottom-summary-ul-li-ol"
                        v-if="chapter.child"
                      >
                        <li
                          class="culture-detail-bottom-summary-ul-li-ol-li"
                          v-for="(_chapter, _index) in chapter.child"
                          :key="_index"
                        >
                          <div
                            class="culture-detail-bottom-summary-ul-li-ol-li-title"
                          >
                            {{ _chapter.name }}
                          </div>
                        </li>
                      </ol>
                    </li>
                  </ul>
                </div>
                <!-- <div v-if="info.chapter_list">
                  <ul class="culture-detail-ul">
                    <li
                      class="culture-detail-ul-li"
                      v-for="(chapter, index) in info.chapter_list"
                      :key="index"
                    >
                      <div class="culture-detail-ul-li-title">
                        {{ chapter.name }}
                      </div>
                      <ol class="culture-detail-ol" v-if="chapter.child">
                        <li
                          class="culture-detail-ol-li"
                          v-for="(_chapter, _index) in chapter.child"
                          :key="_index"
                        >
                          <div class="culture-detail-ol-li-item">
                            <div class="culture-detail-ol-li-icon"></div>
                            <div class="culture-detail-ol-li-title">
                              {{ _chapter.name }}
                            </div>
                          </div>

                          <ol class="culture-detail-ol2" v-if="_chapter.child">
                            <li
                              class="culture-detail-ol2-li"
                              v-for="(_chapter_c, _index_c) in _chapter.child"
                              :key="_index_c"
                            >
                              <div class="culture-detail-ol2-li-item">
                                <div class="culture-detail-ol2-li-icon">
                                  （{{ _index_c + 1 }}）
                                </div>
                                <div class="culture-detail-ol2-li-title">
                                  {{ _chapter_c.name }}
                                </div>
                              </div>
                              <div class="culture-detail-ol2-li-item2">
                                <img
                                  class="culture-detail-ol2-li-icon2"
                                  src="https://image.bookgo.com.cn/%20webculture/general/course-detail-videoTime.png"
                                  alt=""
                                />
                                <div class="culture-detail-ol2-li-title2">
                                  {{ _chapter_c.time }}
                                </div>
                              </div>
                            </li>
                          </ol>
                          <div class="culture-detail-ol-li-item2" v-else>
                            <img
                              class="culture-detail-ol-li-icon2"
                              src="https://image.bookgo.com.cn/%20webculture/general/course-detail-videoTime.png"
                              alt=""
                            />
                            <div class="culture-detail-ol-li-title2">
                              {{ _chapter.time }}
                            </div>
                          </div>
                        </li>
                      </ol>
                      <div class="culture-detail-ul-li-item2" v-else>
                        <img
                          class="culture-detail-ul-li-icon2"
                          src="https://image.bookgo.com.cn/%20webculture/general/course-detail-videoTime.png"
                          alt=""
                        />
                        <div class="culture-detail-ul-li-title2">
                          {{ chapter.time }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mydialog
      v-if="formDialogShow"
      :keyword="'扫码报名培训'"
      :isShow="formDialogShow"
      :src="detailSrc"
      @close="formDialogShow = false"
    />
  </div>
</template>

<script>
import { courseDetail, genQRCode, courseRecommend } from "@/api/common";
import Crumbs from "@/views/components/crumbs";
import mydialog from "@/components/myDialog";
export default {
  components: { mydialog,Crumbs },
  data() {
    return {
      detailSrc: "",
      formDialogShow: false,
      popupTitle: "扫描二维码报名培训",
      pageLoading: true,
      detailType: "culture",
      id: null,
      aid: process.env.VUE_APP_AID,
      info: {},
      culture_team_list: [],
      tid:"",
    };
  },
  created() {
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    //默认加载第一页
    this.tid = this.$route.query.tid;
    this.getDetail();
    this.getRecommendData();
    
  },
  watch: {
    $route(newV) {
      document.documentElement.scrollTop = 0;
      this.tid = newV.query.tid;
      this.getDetail();
    this.getRecommendData();
    },
  },
  methods: {
    changeInfo(id) {
      this.$router.push(`detail?id=${id}`);
      document.documentElement.scrollTop = 0;
    },
    goBack() {
      let routeUrl = this.$router.resolve({
        path: `/cultural`,
        query: { id:this.$route.query.pid,cat_id:this.$route.query.cat_id,navId:this.$route.query.navId,name:this.$route.query.name},
      });
      window.location = routeUrl.href
    //  window.open(routeUrl.href, "_blank");
    },
    handleClosed() {},

    handleServe() {},
    showCode() {
      let params = {
        wxapp_name: "culture",
        page:
          "bookgo/culture/cultivate/detail/detail?id=" +
          this.tid +
          "&aid=" +
          this.aid,
        scene: "",
        aid:process.env.VUE_APP_AID
      };
      this.formDialogShow = true;
      genQRCode(params).then((res) => {
        let pic = res.data;
        this.detailSrc = pic;
      });
    },
    getRecommendData() {
      let params = {
        aid: process.env.VUE_APP_AID,
        course_id: this.tid,
        page: 1,
        page_size: 6,
      };
      courseRecommend(params).then((res) => {
        this.culture_team_list = res.data.datalist;
      });
    },
    getDetail() {
      let params = { id: this.tid };
      courseDetail(params)
        .then((res) => {
          this.pageLoading = false;
          if (res.code == 0) {
            const regex = new RegExp("<img", "gi");
            res.data.content = res.data.content.replace(
              regex,
              `<img style="max-width: 100%; height: auto;margin:0 auto"`
            );
            this.info = res.data;
            this.info.start_date = res.data.start_date.replace(/-/g, ".");
            this.info.end_date = res.data.end_date.replace(/-/g, ".");
            console.info(this.info);
            // this.info.chapter_list.forEach((i) => {
            //   // console.log(3333333)

            //   if (i.child) {
            //     i.child.forEach((j) => {
            //       if (j.child) {
            //         j.child.forEach((k) => {
            //           k["time"] = this.makeDurationToSeconds(k.video_time);
            //         });
            //       } else {
            //         j["time"] = this.makeDurationToSeconds(j.video_time);
            //       }
            //     });
            //   } else {
            //     i["time"] = this.makeDurationToSeconds(i.video_time);
            //   }
            // });
          }
        })
        .catch(() => {
          this.pageLoading = false;
        });
    },
    // makeDurationToSeconds(time) {
    //   // console.log(1,time)
    //   var str = time.toString();
    //   // console.log(2,str)
    //   if (str.indexOf(":") != -1) {
    //     // console.log(444444)
    //     var arr = str.split(":");
    //     // console.log(3,arr)
    //   } else {
    //     // console.log(5555555)
    //     arr = str;
    //     // console.log(3,arr)
    //   }

    //   var Time = "";
    //   var minute = 0;
    //   // var hs = parseInt(arr[0] * 3600);
    //   // var ms = parseInt(arr[1] * 60);
    //   // var ss = parseInt(arr[2]);
    //   // var Time = arr[0]+"小时"+arr[1]+"分钟"+arr[2]+"秒";
    //   if (parseInt(arr[0]) > 0) {
    //     minute += parseInt(arr[0]) * 60;
    //   }
    //   if (parseInt(arr[1]) > 0) {
    //     minute += parseInt(arr[1]);
    //   }
    //   if (minute > 0) {
    //     Time = minute.toString() + "分";
    //   }
    //   if (parseInt(arr[2]) > 0) {
    //     Time = Time + arr[2] + "秒";
    //   }
    //   // var seconds = hs + ms + ss;
    //   return Time;
    // },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/detail.scss";
@import "../../assets/scss/course_default_detail.scss";
@import "../../assets/scss/dialog.scss";
</style>
